import request from '../../utils/request'

//获取评估标准接口分页
export function getDiseaseList(data: any): Promise<any> {
  return request({
    method: 'GET',
    data: data,
    type: 'params',
    url: 'manage/disease/list'
  })
}

// 获取评估标准详情数据
export function getDetailDisease(id: number): Promise<any> {
  return request({
    method: 'GET',
    url: 'manage/disease/detail/  ' + id
  })
}

interface DiseaseStandardList {
  comment: string;
  highestScore: number | string;
  lowestScore: number | string;
  remark: string;
  riskLevel: string;
  [propName: string]: any;
}
interface PostDetail {
  diseaseName: string;
  diseaseType: string;
  diseaseStandardList: DiseaseStandardList[];
  [propName: string]: any;
}
// 新增修改评估标准
export function postDetailDisease(data: PostDetail): Promise<any> {
  return request({
    method: 'POST',
    data: data,
    url: 'manage/disease/submit'
  })
}

// 删除评估标准
export function deleteDetail(ids: number): Promise<any> {
  return request({
    method: 'POST',
    url: 'manage/disease/remove?ids=' + ids
  })
}

// // 获取详情标准
export function getOneDetail(ids: number): Promise<any> {
  return request({
    method: 'GET',
    url: '/manage/disease/detail/' + ids
  })
}
import request from '../../utils/request'

//获取菜单树结构
export function getMenuTree(data: any): Promise<any> {
  return request({
    method: 'GET',
    data,
    type: 'params',
    url: 'manage/ques/list',
  })
}

//问卷的添加修改
export function getpostQuestionList(data: any): Promise<any> {
  return request({
    method: 'POST',
    data,
    url: 'manage/ques/submit',
  })
}

// 获取评估标准接口
export function getDisease(data?: any): Promise<any> {
  return request({
    method: 'GET',
    data: data,
    type: 'params',
    url: 'manage/disease/list'
  })
}

//  删除问卷接口
export function delDisease(id: number): Promise<any> {
  return request({
    method: 'POST',
    url: 'manage/ques/remove?ids=' + id
  })
}
// 获取详细问卷详细信息接口
export function getDetailDisease(id: number): Promise<any> {
  return request({
    method: 'GET',
    url: 'manage/ques/detail/' + id
  })
}

//获取菜单树结构
export function getMenuTreeApi(data: any): Promise<any> {
  return request({
    method: 'GET',
    data,
    type: 'params',
    url: 'manage/questionnaire/pageList',
  })
}

//问卷的添加
export function getpostQuestionListApi(data: any): Promise<any> {
  return request({
    method: 'POST',
    data,
    url: 'manage/questionnaire/add',
  })
}

//问卷的修改
export function getpostQuestionListUpdate(data: any): Promise<any> {
  return request({
    method: 'POST',
    data,
    url: 'manage/questionnaire/update',
  })
}



//  删除问卷接口localhost:7001/manage/questionnaire/delete
export function delDiseaseApi(id: number): Promise<any> {
  return request({
    method: 'POST',
    data:{
      "id":id
    },
    url: 'manage/questionnaire/delete'
  })
}
// 获取详细问卷详细信息接口
export function getDetailDiseaseApi(id: number): Promise<any> {
  return request({
    method: 'GET',
    
    url: 'manage/questionnaire/getById?id=' + id
  })
}

// 问题列表 
export function getItemPageList(data: any): Promise<any> {
  return request({
    method: 'GET',
    data: data,
    type: 'params',
    url: 'manage/questionnaire/itemPageList'
  })
}

// 问题详情 localhost:7001/manage/questionnaire/getItemById?id=1
// export function getItemById(id: number): Promise<any> {
//   return request({
//     method: 'GET',
    
//     url: 'manage/questionnaire/getItemById?id='+id
//   })
// }

// localhost:7001/manage/questionnaire/answerPageList?size=3&current=1&keyword=
// 答案分页
export function getItemById(data: any): Promise<any> {
  return request({
    method: 'GET',
    data: data,
    type: 'params',
    url: 'manage/questionnaire/answerPageList'
  })
}

// localhost:7001/manage/questionnaire/deleteItem 
//删除问题
export function getDeleteItem(data: any): Promise<any> {
  return request({
    method: 'POST',
    data: data,
    
    url: 'manage/questionnaire/deleteItem'
  })
}

// 添加问题
export function getAddItem(data: any): Promise<any> {
  return request({
    method: 'POST',
    data: data,
    
    url: 'manage/questionnaire/addItem'
  })
}

// 题目更新

export function getUpdateItem(data: any): Promise<any> {
  return request({
    method: 'POST',
    data: data,
    
    url: 'manage/questionnaire/updateItem'
  })
}

//答案新增
// localhost:7001/manage/questionnaire/addAnswer
export function getAddAnswer(data: any): Promise<any> {
  return request({
    method: 'POST',
    data: data,
    
    url: 'manage/questionnaire/addAnswer'
  })
}

// 答案删除
export function getDeleteAnswer(data: any): Promise<any> {
  return request({
    method: 'POST',
    data: data,
    
    url: 'manage/questionnaire/deleteAnswer'
  })
}

// 答案更新

export function getUpdateAnswer(data: any): Promise<any> {
  return request({
    method: 'POST',
    data: data,
    
    url: 'manage/questionnaire/updateAnswer'
  })
}

// 一键复制
export function copyApi(data: any): Promise<any> {
  return request({
    method: 'POST',
    data: data,
    
    url: 'manage/questionnaire/copy'
  })
}

// TODO：满意度调查
export function getList(data: any): Promise<any> {
  return request({
    method: 'GET',
    data: data,
    type: 'params',
    url: 'manage/satisfaction/getList'
  })
}
